import { Grid, GridItem, Flex, Button, Box, Text } from '@chakra-ui/react'
import { differenceInDays, differenceInHours } from 'date-fns'
import { useTranslation } from 'next-i18next'
import { ForwardedRef, forwardRef, useEffect, useState } from 'react'

import {
  FrontCollectGoalType,
  FrontCollectTypeType,
  FrontLendingProductKindType,
  FrontProjectStateType,
  FrontTermFrequencyType,
  getBERegionNameAndIconByCode,
  getFRRegionNameAndIconByCode,
} from '@miimosa/common'
import { Icon, IconName, toV2Link, usePlatform } from '@miimosa/design-system'

import { CollectEndedInfo, HeadingWithDot, ProjectImageOrVideo } from '@components'

interface Props {
  imageFileName?: string
  projectId: number
  projectSlug?: string
  shortDescription: string
  place: string
  progress: number
  contributorsCount: number
  endsAt: string
  collectedAmount: number
  goalAmount: number
  goalType: FrontCollectGoalType
  goalQuantity: number
  collectedQuantity: number
  projectStatus: FrontProjectStateType
  regionalCode: string
  videoUrl?: string | null
  isPreview?: boolean
  collectType?: FrontCollectTypeType
  termQuantity?: number | null
  termFrequency?: FrontTermFrequencyType | null
  interestsRatio?: number | null
  lendingProductKind?: FrontLendingProductKindType
  successTriggerRatio: number
  rewardsLength: number
}

const Hero = forwardRef(function Hero(
  {
    imageFileName,
    projectId,
    projectSlug,
    shortDescription,
    place,
    progress,
    contributorsCount,
    endsAt,
    collectedAmount,
    goalAmount,
    goalType,
    goalQuantity,
    collectedQuantity,
    projectStatus,
    regionalCode,
    videoUrl,
    isPreview,
    collectType = 'donation',
    termQuantity,
    termFrequency,
    interestsRatio,
    lendingProductKind,
    successTriggerRatio,
    rewardsLength,
  }: Props,
  ref: ForwardedRef<HTMLButtonElement>
) {
  const { t } = useTranslation('common')
  const { t: tProject } = useTranslation('project')
  const [progressForBar, setProgressForBar] = useState(0)
  const { platform, lang } = usePlatform()

  let remaining = differenceInDays(new Date(endsAt), Date.now())
  let key = 'medium_relative_time.day'
  if (remaining == 0) {
    remaining = differenceInHours(new Date(endsAt), Date.now())
    key = 'medium_relative_time.hour'
  }

  let region = { name: '', iconName: '' }
  if (platform == 'be') {
    region = getBERegionNameAndIconByCode({ regionalCode: regionalCode })
  } else {
    region = getFRRegionNameAndIconByCode({ regionalCode: regionalCode })
  }

  const isDonation = collectType === 'donation'
  const barColor = isDonation ? 'green' : 'blue'

  useEffect(() => {
    setProgressForBar(projectStatus == 'funded' ? 100 : progress)
  }, [progress, projectStatus])

  return (
    <Grid templateColumns={{ base: 'repeat(1, 1fr)', lg: 'repeat(2, 1fr)' }} my={6} gap={{ base: 8, lg: 14 }} w="full">
      <GridItem w="100%" display="flex" flexDirection="column" alignItems="center">
        <ProjectImageOrVideo videoUrl={videoUrl} imageFileName={imageFileName} projectId={projectId} />
      </GridItem>
      <GridItem w="100%" alignItems="center">
        <Flex direction="column" justifyContent="space-evenly" h="full" rowGap={{ base: 5, lg: 0 }}>
          <HeadingWithDot
            collectType={collectType}
            goalType={goalType}
            termFrequency={termFrequency}
            termQuantity={termQuantity}
            interestsRatio={interestsRatio}
            lendingProductKindType={lendingProductKind!}
            rewardsLength={rewardsLength || 0}
          />
          <Flex direction="row" position="relative">
            <Text
              size="lg"
              color="#2A2A2A"
              noOfLines={{ base: 0, lg: 4 }}
              overflow="hidden"
              textAlign="justify"
              _before={{
                minW: '20px',
                content: '""',
                backgroundImage: '/images/up_brush_3.svg',
                backgroundRepeat: 'no-repeat',
                height: '20px',
                display: 'inline-flex',
                backgroundSize: 'contain',
                width: '20px',
                transform: 'rotateY(180deg)',
                position: 'absolute',
                top: '-10px',
                left: '-20px',
              }}
              _after={{
                content: '""',
                backgroundImage: '/images/down_brush_2.svg',
                backgroundRepeat: 'no-repeat',
                height: '15px',
                display: 'inline-flex',
                backgroundSize: 'contain',
                width: '15px',
                transform: 'rotateY(180deg)',
                position: 'relative',
                top: '10px',
                marginLeft: '2px',
              }}
            >
              {shortDescription}
            </Text>
          </Flex>
          <Flex direction={{ base: 'column', lg: 'row' }} rowGap={2}>
            {region && (
              <Flex direction="row" alignItems="center" columnGap={2} w="full">
                {region.iconName && <Icon name={region.iconName as IconName} size="xs" fill="dark_green" />}
                {region.name && (
                  <Text size="md" color="#757575" maxW="250px">
                    {region.name}
                  </Text>
                )}
              </Flex>
            )}
            <Flex direction="row" alignItems="center" columnGap={2} w="full">
              <Flex w="48px" h="48px" justifyContent="center" alignItems="center">
                <Icon name={'Pin'} size="3xs" color="orange" />
              </Flex>
              <Text size="md" color="#757575" noOfLines={2} maxW="300px">
                {place}
              </Text>
            </Flex>
          </Flex>
          <Flex direction="column" rowGap={4}>
            <Flex direction="row" alignItems="center" columnGap={3}>
              <Box w="full" h="10px" bg="#F2F2F2" borderRadius="7px">
                <Box
                  h="full"
                  bg={projectStatus == 'failed' ? 'main_gray_lighten' : barColor}
                  w={`${progressForBar}%`}
                  maxW="100%"
                  borderLeftRadius="7px"
                  borderRightRadius={projectStatus == 'funded' || progress >= 100 ? '7px' : '0px'}
                  transition="1s ease"
                />
              </Box>
              <Text size="sm">{progress}%</Text>
            </Flex>
            <Flex direction="row" justifyContent="space-between">
              <Flex direction="column">
                <Text size="lg" fontWeight="bold">
                  {projectSlug == 'la-quercynoise-groupement-majeur-du-sud-ouest-de-la-france'
                    ? contributorsCount + 348
                    : contributorsCount}
                </Text>
                <Text size="sm" color="#757575" textTransform="lowercase">
                  {isDonation ? tProject('contributors_key') : tProject('lenders_key')}
                </Text>
              </Flex>
              {projectStatus != 'funded' && projectStatus != 'failed' && (
                <Flex direction="column" alignItems="center">
                  <Text size="lg" fontWeight="bold">
                    {t(key, { count: remaining })}
                  </Text>
                  <Text size="sm" color="#757575">
                    {tProject('left', { count: remaining })}
                  </Text>
                </Flex>
              )}
              <Flex direction="column" alignItems="flex-end">
                {goalType === 'amount' ? (
                  <>
                    <Text size="lg" fontWeight="bold">
                      {new Intl.NumberFormat('fr-FR', {
                        style: 'currency',
                        currency: 'EUR',
                        maximumFractionDigits: 0,
                      }).format(collectedAmount)}
                    </Text>
                    <Text size="sm" color="#757575">
                      {tProject('on')}{' '}
                      {new Intl.NumberFormat('fr-FR', {
                        style: 'currency',
                        currency: 'EUR',
                        maximumFractionDigits: 0,
                      }).format(goalAmount)}
                    </Text>
                  </>
                ) : (
                  <>
                    <Text size="lg" fontWeight="bold">
                      {new Intl.NumberFormat('fr-FR', {
                        maximumFractionDigits: 0,
                      }).format(collectedQuantity)}{' '}
                      {tProject('presales')}
                    </Text>
                    <Text size="sm" color="#757575">
                      {tProject('goal', { val: goalQuantity })}
                    </Text>
                  </>
                )}
              </Flex>
            </Flex>
          </Flex>
          {projectStatus != 'funded' && projectStatus != 'failed' && (
            <Flex direction="column" rowGap={4} alignItems="center">
              <Button
                size="lg"
                variant="primary"
                w="full"
                className="add_cart"
                isDisabled={isPreview ? true : false}
                disabled={isPreview ? true : false}
                onClick={() => (location.href = toV2Link(`/projects/${projectSlug}/pledges/new`, platform, lang))}
                ref={ref}
              >
                <Text size="md">{isDonation ? tProject('collect.support') : tProject('collect.lend')}</Text>
              </Button>
              <Text size="sm" color="#757575" textAlign={'center'} whiteSpace="pre-line">
                {tProject('collect.refund_rules3', { val: successTriggerRatio * 100 })}
              </Text>
            </Flex>
          )}

          <CollectEndedInfo projectStatus={projectStatus} endsAt={endsAt} />
        </Flex>
      </GridItem>
    </Grid>
  )
})

export default Hero
