import { Box, Container, Divider, useBreakpointValue } from '@chakra-ui/react'
import { useContext, useRef } from 'react'

import {
  type FrontProjectType,
  Subscription,
  FullArticleType,
  articleType,
  CommentsType,
  contributorsType,
} from '@miimosa/common'
import { UserContext } from '@miimosa/design-system'

import { AdminBar, ProjectNavBarInterface } from '@components'

import Hero from './Hero'
import ImpactsList from './ImpactsList'
import TopInformations from './TopInformations'

interface Props {
  project: FrontProjectType
  activeTab: string
  subscriptionState: Subscription['subscription'] | null
  articles?: articleType[]
  article?: FullArticleType
  isPreview?: boolean
  comments?: CommentsType
  contributors?: contributorsType
}

const DonationProjectInterface = ({
  project,
  activeTab,
  subscriptionState,
  articles,
  article,
  comments,
  contributors,
  isPreview = false,
}: Props) => {
  const user = useContext(UserContext)
  const isMobile = useBreakpointValue({ base: true, lg: false }, { fallback: 'lg' })

  const buttonRef = useRef<HTMLButtonElement>(null)

  const showAdminBar = user?.is_admin || user?.id === project.userId

  return (
    <Container variant="full">
      <Container variant="boxed" py={4}>
        {showAdminBar && (
          <>
            <AdminBar slug={project.slug || ''} />
            {isMobile ? <Box my={2} /> : <Divider my={5} />}
          </>
        )}
        <TopInformations title={project.title} userFullName={project.userFullName} />
        <Hero
          imageFileName={project.description.imageFileName}
          projectId={project.id}
          projectSlug={project.slug || ''}
          shortDescription={project.description.shortDescription}
          place={project.description.place}
          progress={project.collect.progress}
          contributorsCount={project.contributorsCount}
          endsAt={project.collect.endsAt}
          collectedAmount={project.collect.collectedAmount}
          goalAmount={project.collect.goalAmount}
          goalType={project.collect.goalType}
          goalQuantity={project.collect.goalQuantity}
          collectedQuantity={project.collect.collectedQuantity}
          projectStatus={project.state}
          regionalCode={project.regionalCode}
          videoUrl={project.description.videoUrl}
          isPreview={isPreview}
          successTriggerRatio={project.collect.successTriggerRatio}
          rewardsLength={project.rewards.length}
          ref={buttonRef}
        />
        {project.labels.length > 0 && <ImpactsList labels={project.labels} />}
      </Container>
      <ProjectNavBarInterface
        collectType={project.collect.type}
        description={project.description.description}
        usageDescription={project.description.usageDescription}
        facebookUrl={project.description.facebookUrl}
        twitterUrl={project.description.twitterUrl}
        instagramUrl={project.description.instagramUrl}
        linkedinUrl={project.description.linkedinUrl}
        websiteUrl={project.description.websiteUrl}
        partnerLogos={project.partnerLogos}
        contributorsCount={project.contributorsCount}
        articlesCount={project.articlesCount}
        commentsCount={project.commentsCount}
        activeTab={activeTab}
        subscriptionState={subscriptionState}
        projectSlug={project.slug || ''}
        articles={articles}
        article={article}
        rewards={project.rewards}
        isPreview={isPreview}
        goalType={project.collect.goalType}
        buttonRef={buttonRef}
        termFrequency={'monthly'}
        termQuantity={0}
        interestsRatio={0}
        comments={comments}
        contributors={contributors}
      />
    </Container>
  )
}

export default DonationProjectInterface
